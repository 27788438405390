import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"

export const CategoryTitle = styled.div`
  color: #222;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 1.6px;
  text-transform: capitalize;
  text-align: left;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
  }
`
