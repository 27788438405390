import React from "react";
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"

import CountUp from 'react-countup'

const Wrapper = styled.div<{flexDirection: string}>`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  flex-wrap: wrap;
  gap: 16px;
`

const List = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 4px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    min-width: max-content;
  }
`

const Prefix = styled.span`
  color: #222;
  text-align: center;

  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 12px;
    line-height: 18px;
  }
`

const Number = styled.span<{color: string}>`
  color: ${props => props.color};

  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  text-transform: capitalize;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 24px;
    line-height: 24px;
  }
`

const Suffix = styled.span`
  color: #222;
  text-align: center;

  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.7px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.6px;
  }
`

type ListProps = {
  prefix: string
  value: number
  suffix : string
  color: string
}

type Props = {
  data: Array<ListProps>
  flexDirection?: string
};

export const DataList: React.VFC<Props> = (props) => {
  const { data, flexDirection = 'row' } = props;
  return (
    <Wrapper flexDirection={flexDirection}>
      {
        data.map((d) => {
          return (
            <List>
              <Prefix>{d.prefix}</Prefix>
              <Number color={d.color}>
                <CountUp end={d.value} />
              </Number>
              <Suffix>{d.suffix}</Suffix>
            </List>
          )
        })
      }
    </Wrapper>
  )
}
