import React from "react";
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Graph = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 96px;
    height: auto;
  }
`

type Props = {
  graph: any
  width: string
  height: string
};

export const DataGraph: React.VFC<Props> = (props) => {
  const { graph, width, height } = props;
  return (
    <Wrapper>
      <Graph src={graph} width={width} height={height} />
    </Wrapper>
  )
}
