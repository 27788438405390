import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import 'swiper/css'
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination } from 'swiper'

import { TitleAndCaption } from '../common/TitleAndCaption'
import { RECRUIT_PAGE_STYLES, RESPONSIVE_STYLES } from '../../../AppConst'

const Wrapper = styled.div`
  padding: 160px 0;
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    padding: 34px 0;
  }
`
const Container = styled.div`
  ${RECRUIT_PAGE_STYLES.MARGIN_TOP_STYLE}
  & .swiper {
    padding-bottom: 100px;
  }
  & .swiper-pagination {
    padding-bottom: 38px;
  }
`

export const Gallery: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {sourceInstanceName: {eq: "gallery"}}
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const gallery = data.allFile.edges
  return (
    <Wrapper>
      <TitleAndCaption title="ギャラリー" subTitle="GALLERY"/>
      <Container>
        <Swiper
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          slidesPerView={1.2}
          spaceBetween={24}
          speed={500}
          centeredSlides={true}
          modules={[Autoplay, Pagination]}
          breakpoints= {{
            520: {
              slidesPerView: 2.2,
            },
            1080: {
              slidesPerView: 3,
              spaceBetween: 80,
            },
          }}
        >
          {gallery.map((photo, idx: number) => {
            return(
              <SwiperSlide key={idx}>
                <Img fluid={photo.node.childImageSharp.fluid} alt="gallery"/>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Container>
    </Wrapper>
  )
}
