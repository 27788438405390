import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 4px;
  }
`

const Icon = styled.img`
  width: 30px;
  height: 30px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    width: 21px;
    height: 21px;
  }
`

const Title = styled.h4`
  margin: 0;

  color: #222;
  text-align: center;

  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 1px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.9px;
  }
`

type Props = {
  icon: any
  title: string
}

export const DataTitle: React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      <Icon src={props.icon} />
      <Title>{props.title}</Title>
    </Wrapper>
  )
}