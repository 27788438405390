import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { RESPONSIVE_STYLES, OWNEDMEDIA_WORDPRESS_URL } from "../../../AppConst";
import { ReadMore } from '../../common/ReadMore'
import { ArticleCard } from "../../index/v2/blog/ArticleCard";
import { WPFrontMatter } from "../../../types/WPFrontMatter";
import { graphql, useStaticQuery } from "gatsby";
import { LoadingAPI } from "../../common/LoadingAPI";
import { ConnectionFailedText } from "../../common/ConnectionFailedText";

const fetchInterviewPosts = async (setPosts, setIsLoading, setHasError) => {
  setIsLoading(true);

  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/tags?slug=インタビュー`)
  .then(response => {return response.json()})
  .then(tagData => {
    fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&tags=${tagData[0].id}&per_page=3`)
    .then(response => {return response.json()})
    .then(postsData => {
      const posts: WPFrontMatter[] = [];

      postsData.map((data, i) => {
        const postData: WPFrontMatter = {
          title: data.title.rendered,
          description: "",
          date: data.date,
          category: data._embedded["wp:term"][0][0].slug,
          author: data._embedded.author[0].name,
          thumbnail: data._embedded["wp:featuredmedia"][0].source_url,
          slug: `${data._embedded.author[0].slug}/${data.slug}`
        }
        posts.push(postData);
      })

      setPosts(posts);
      setIsLoading(false);
    })
    .catch((error) => {
      setHasError(true);
    });
  })
  .catch((error) => {
    setHasError(true);
  });
};

/**
 * Elements & Styles
 */
const Node = styled.div`
  text-align: center;
  max-width: 1080px;
  margin: 48px auto 0;
  display: flex;
  flex-direction: column;
  & .title--pickup_article {
    text-align: center;
    font-family: 'Righteous';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #222222;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      font-size: min(4.2vw, 2.4rem);
      letter-spacing: 1.6px;
      padding: 0 0 0.3em;
    }
  }
`
const ArticleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 36px;
  flex-wrap: wrap;
  margin-bottom: 80px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 48px;
    gap: 24px;
  }
`

/**
 * Component
 */
export const InterviewArticleList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [posts, setPosts] = useState<WPFrontMatter[]>([]);

  useEffect(() => {
    fetchInterviewPosts(setPosts, setIsLoading, setHasError);
  }, [])

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          member {
            name
            filename
            pathname
          }
        }
      }
    }
  `)

  const members = site.siteMetadata.member;

  return (
    <Node>
      { hasError ? <ConnectionFailedText /> :
          isLoading ? <LoadingAPI /> :
            <>
              <ArticleWrapper>
                {posts.map((post, i) => {
                  const member: { name: string, filename: string } = members.find((m) => m.name == post.author);

                  return (
                    <ArticleCard
                      key={i}
                      frontMatter={post}
                      member={member}
                    />
                  )
                })}
              </ArticleWrapper>
              <ReadMore url='/tags/インタビュー/' darkMode={true} text='ブログ一覧を見る' />
            </>
      }
    </Node>
  )
}
