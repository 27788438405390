import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"

export const DataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between;
  gap: 24px 0;

  width: 100%;
  box-sizing: border-box;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 16px 0;
  }
`
