import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../AppConst"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;

  width: 100%;
  max-width: 1080px;
  margin: auto;
  box-sizing: border-box;

  padding: 160px 0 0;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 40px;
    padding: 64px 20px 0;
  }
`
