import React from "react";
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"

import CountUp from 'react-countup'

const NumberWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 2px;
`

const Number = styled.span<{fontSize: number}>`
  color: #61B1C1;
  text-align: center;
  font-size: calc(88px * (${props => props.fontSize} / 100));
  font-weight: 600;
  line-height: calc(88px * (${props => props.fontSize} / 100));
  text-transform: capitalize;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 48px;
    line-height: 48px;
  }
`

const Suffix = styled.span`
  color: #222;
  text-align: center;

  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 1.2px;
  text-transform: capitalize;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.7px;
  }
`

type Props = {
  value: number
  suffix: string
  fontSize?: number
  decimals? : number
};

export const DataNumber: React.VFC<Props> = (props) => {
  const { value, decimals = 0, suffix, fontSize = 100} = props;
  return (
    <NumberWrapper>
      <Number fontSize={fontSize}>
        <CountUp end={value} decimals={decimals} />
      </Number>
      <Suffix>{suffix}</Suffix>
    </NumberWrapper>
  )
}
