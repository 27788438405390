import React from "react"
import { graphql } from "gatsby"
import { CoWorkers } from "../../../components/recruit/coworks"

const breadcrumbsData: { text: string; path: string }[] = [
    { text: `RECRUIT`, path: `recruit` },
    { text: `働く人・環境`, path: `coworkers` },
]

export default function CoWorkersPage({ data }) {
  const {
    headerImg,
  } = data
  return (
    <CoWorkers
      breadcrumbsData={breadcrumbsData}
      headerImg={headerImg.childImageSharp.fluid}
    />
  )
}

export const query = graphql`
  query CoWorkersQuery {
    headerImg: file(relativePath: { eq: "images/recruit/coworkers/img_recruit_coworkers_top.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`