import React from 'react'
import styled from 'styled-components'

import { TitleAndCaption } from '../common/TitleAndCaption'
import { InterviewArticleList } from '../common/IntervewArticleList'
import { RESPONSIVE_STYLES } from '../../../AppConst'

const Wrapper = styled.div`
  background: #EFEFEF;
  padding: 160px 20px 160px;
  box-sizing: border-box;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 80px 20px 32px;
  }
`

export const IntervewBlog: React.FC = () => {
  return (
    <Wrapper>
      <TitleAndCaption title="社内インタビュー記事" subTitle="INTERVIEW"/>
      <InterviewArticleList />
    </Wrapper>
  )
}
