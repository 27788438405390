import React from "react";
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"

import CountUp from 'react-countup'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px 24px;
`

const CoinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Title = styled.h3`
  margin: 0;

  color: #222;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.8px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.6px;
  }
`

const ValueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
`

const Value = styled.span`
  color: #61B1C1;

  text-align: center;
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  text-transform: capitalize;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 24px;
    line-height: 24px;
  }
`

const Suffix = styled.span`
  color: #222;
  text-align: center;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.8px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.6px;
  }
`

const Message = styled.p`
  margin: 0;

  width: 100%;
  color: #222;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.7px;
  text-align: left;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.6px;
  }
`

type Props = {};

export const DataCoin: React.VFC<Props> = () => {
  return (
    <Wrapper>
      <CoinWrapper>
        <Title>年間流通回数</Title>
        <ValueWrapper>
          <Value>
            <CountUp end={34561} separator={","} />
          </Value>
          <Suffix>回</Suffix>
        </ValueWrapper>
      </CoinWrapper>
      <CoinWrapper>
        <Title>年間流通コイン</Title>
        <ValueWrapper>
          <Value>
            <CountUp end={889990} separator={","} />
          </Value>
          <Suffix>コイン</Suffix>
        </ValueWrapper>
      </CoinWrapper>
      <Message>小さなことでも「ありがとう」を伝えるために、独自に構築したNextCoinという仮想通貨を、メンバー間で贈り合っています。</Message>
    </Wrapper>
  )
}
