import React from "react"
import styled, {css} from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"

import { FadeIn } from '../../../../../common/FadeIn'
import { FadeInOptions } from './../../../FadeInOptions'

const Wrapper = styled.div`
  width: 100%;
  max-width: 520px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: 345px;
  }
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    max-width: none;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  min-height: 320px;
  padding: 24px;
  box-sizing: border-box;
  background-color: #F4F4F4;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 16px;
    min-height: 216px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
`

const Icon = styled.img`
  width: 30px;
  height: 30px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 21px;
    height: 21px;
  }
`
const Title = styled.h3`
  margin: 0;

  color: #222;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 1px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.7px;
  }
`

const FirstPlaceWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  padding-bottom: 4px;
  border-bottom: 2px solid #BDBDBD;
  box-sizing: border-box;
`
const FirstLabel = styled.span`
  color: #222;
  text-align: center;

  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 1.6px;
  text-transform: capitalize;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
  }
`
const FirstUnit = styled.span`
  color: #222;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 1px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.7px;
  }
`
const FirstName = styled.p`
  margin: 0 0 0 16px;
  color: #61B1C1;

  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 1.6px;
  text-transform: capitalize;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
  }
`

const OthersWrapper = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px 16px;

  margin: 0;
  padding: 0;
  list-style: none;
`
const OtherName = styled.li`
  color: #222;

  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 1px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.7px;
  }
`

type Props = {
  icon: any
  title: string
  name: string
  names: Array<string>
}
export const Ranking: React.VFC<Props> = (props) => {
  const { icon, title, name, names } = props;

  return (
    <Wrapper>
      <FadeIn {...FadeInOptions}>
        <Inner>
          <TitleWrapper>
            <Icon src={icon} />
            <Title>{title}</Title>
          </TitleWrapper>
          <FirstPlaceWrapper>
            <FirstLabel>1</FirstLabel>
            <FirstUnit>位</FirstUnit>
            <FirstName>
              <FadeIn {...FadeInOptions}>
                {name}
              </FadeIn>
            </FirstName>
          </FirstPlaceWrapper>
          <OthersWrapper>
            {
              names.map((n, i) => {
                const options = { ...FadeInOptions, delay: (i * 150) };
                return (
                  <OtherName>
                    <FadeIn {...options}>
                      {n}
                    </FadeIn>
                  </OtherName>
                )
              })
            }
          </OthersWrapper>
        </Inner>
      </FadeIn>
    </Wrapper>
  )
}