import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"

export const Title = styled.h3`
  position: relative;
  margin: 0;
  padding-bottom: 8px;

  color: #222;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 1.6px;
  text-transform: capitalize;
  text-align: left;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #BDBDBD;
  }

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.8px;
  }
`
