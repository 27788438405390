import React from "react"
import styled, {css} from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"
import { Title } from "./Title";

import { FadeIn } from '../../../../../common/FadeIn'
import { FadeInOptions } from './../../../FadeInOptions'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
  }

  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    gap: 16px;
  }
`

const Inner = styled.div<{width: string}>`
  width: 100%;
  max-width: ${props => props.width};
  margin: auto;
`

const Answers = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px 24px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px 16px;
  }

  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    gap: 16px 8px;
  }

  & > div {
    display: flex;
    width: fit-content;
  }
`

const Answer = styled.span<{left: boolean, color?: string}>`
  display: flex;
  padding: 16px 24px;
  box-sizing: border-box;
  border-radius: 24px 24px 24px 24px;
  background: #F4F4F4;

  color: #222;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 1px;

  ${props => props.left ? css`
    border-radius: 24px 24px 24px 0px;
  ` : css`
    border-radius: 24px 24px 0px 24px;
  `}
  ${props => props.color && css`
    background: #FFF;
    border: 4px solid ${props.color};

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      border: 2px solid ${props.color};
    }
  `}

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 6px 18px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.7px;
  }

  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    padding: 6px 12px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.6px;
  }
`

type Props = {
  title: string
  innerWidth?: string
  answers: Array<{
    answer: Array<string> // 配列を改行
    left?: boolean
    color?: string // 例） #ffffff
  }>
};
export const QA: React.VFC<Props> = (props) => {
  const { title, innerWidth = "1080px", answers } = props;

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Inner width={innerWidth}>
        <Answers>
          {
            answers.map((a, i) => {
              const {answer, left = false, color = ""} = a;
              const options = {...FadeInOptions, delay: (i * 150)};
              return (
                <FadeIn {...options}>
                  <Answer left={left} color={color} dangerouslySetInnerHTML={{ __html: answer.join("<br />") }}/>
                </FadeIn>
              )
            })
          }
        </Answers>
      </Inner>
    </Wrapper>
  )
}
