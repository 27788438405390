import React from "react";
import styled from "styled-components";

import { Wrapper } from "../../components/common/Wrapper";
import { TitleAndCaption } from "../../../common/TitleAndCaption";
import { TitleWrapper } from "../common/TitleWrapper";
import { CategoryTitle } from "./common/CategoryTitle";
import { DataContainer } from "./common/DataContainer";
import { DataNumber } from "./common/DataNumber";

import { OneColumnData } from "./common/OneColumnData";
import { TwoColumnData } from "./common/TwoColumnData";
import { DataGraph } from "./common/DataGraph";
import { DataList } from "./common/DataList";
import { RESPONSIVE_STYLES } from "../../../../../AppConst";

import IconAlarm from "./../images/alarm.svg"
import IconApartment from "./../images/apartment.svg"
import IconCoin from "./../images/coin.svg"
import IconEscalatorWarning from "./../images/escalator_warning.svg"
import IconFace from "./../images/face.svg"
import IconMembers from "./../images/members.svg"
import IconPC from "./../images/pc.svg"
import IconPlace from "./../images/place.svg"
import IconTrain from "./../images/train.svg"
import IconGender from "./../images/gender.svg"
import IconWork from "./../images/work.svg"
import PlaceGraph from "./../images/map.svg"
import TrainGraph from "./../images/train_graph.svg"
import GenderGraph from "./../images/gender_graph.svg"
import WorkGraph from "./../images/work_graph.svg"
import WorkstyleGraph from "./../images/workstyle_graph.svg"
import { DataCoin } from "./common/DataCoin";

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 16px;
  }
`

type Props = {};

export const Data: React.VFC<Props> = () => {
  return (
    <Wrapper>
      <TitleWrapper>
        <TitleAndCaption title='数字で見るネクストライブ' subTitle='data of nextribe' supplemental='※2023年5月調べ' />
      </TitleWrapper>

      <CategoryContainer>
        <CategoryTitle>会社情報</CategoryTitle>
        <DataContainer>
          <OneColumnData
            icon={IconMembers}
            title="社員数"
            width="33%"
            content={<DataNumber value={30} suffix="名" />}
          />
          <TwoColumnData
            icon={IconFace}
            title="平均年齢"
            width="33%"
            content1={<DataNumber value={32.8} decimals={1} suffix="歳" fontSize={70} />}
            content2={
              <DataList
              data={[
                {
                  prefix: "20代",
                  value: 43,
                  suffix: "%",
                  color: "#DBAAC8",
                },
                {
                  prefix: "30代",
                  value: 25,
                  suffix: "%",
                  color: "#DDD88C",
                },
                {
                  prefix: "40代",
                  value: 32,
                  suffix: "%",
                  color: "#84C17E",
                },
              ]}
              flexDirection="column"
            />}
          />
          <TwoColumnData
            icon={IconGender}
            title="男女比"
            width="33%"
            content1={<DataGraph graph={GenderGraph} width="144px" height="144px" />}
            content2={
              <DataList
              data={[
                {
                  prefix: "男性",
                  value: 69,
                  suffix: "%",
                  color: "#61B1C1",
                },
                {
                  prefix: "女性",
                  value: 31,
                  suffix: "%",
                  color: "#DBAAC8",
                },
              ]}
              flexDirection="column"
            />}
          />
          <TwoColumnData
            icon={IconWork}
            title="職種"
            width="50%"
            content1={<DataGraph graph={WorkGraph} width="216px" height="216px" />}
            content2={
              <DataList
              data={[
                {
                  prefix: "エンジニア",
                  value: 85,
                  suffix: "%",
                  color: "#61B1C1",
                },
                {
                  prefix: "デザイナー",
                  value: 7,
                  suffix: "%",
                  color: "#DBAAC8",
                },
                {
                  prefix: "営業",
                  value: 4,
                  suffix: "%",
                  color: "#DDD88C",
                },
                {
                  prefix: "バックオフィス",
                  value: 4,
                  suffix: "%",
                  color: "#84C17E",
                },
              ]}
              flexDirection="column"
            />}
          />
          <TwoColumnData
            icon={IconPlace}
            title="住んでいる県"
            width="50%"
            content1={<DataGraph graph={PlaceGraph} width="202px" height="216px" />}
            content2={
              <DataList
              data={[
                {
                  prefix: "大阪",
                  value: 64,
                  suffix: "%",
                  color: "#61B1C1",
                },
                {
                  prefix: "京都",
                  value: 7,
                  suffix: "%",
                  color: "#DDD88C",
                },
                {
                  prefix: "兵庫",
                  value: 11,
                  suffix: "%",
                  color: "#DBAAC8",
                },
                {
                  prefix: "滋賀",
                  value: 4,
                  suffix: "%",
                  color: "#EDB568",
                },
                {
                  prefix: "奈良",
                  value: 11,
                  suffix: "%",
                  color: "#84C17E",
                },
                {
                  prefix: "和歌山",
                  value: 4,
                  suffix: "%",
                  color: "#A1ADDD",
                },
              ]}
              flexDirection="row"
            />}
          />
        </DataContainer>
      </CategoryContainer>

      <CategoryContainer>
        <CategoryTitle>働きやすさ</CategoryTitle>
        <DataContainer>
          <OneColumnData
            icon={IconAlarm}
            title="平均残業時間/月"
            width="33%"
            content={
              <DataNumber
                value={14}
                suffix="時間"
              />
            }
          />
          <OneColumnData
            icon={IconPC}
            title="リモートワーク率"
            width="33%"
            content={
              <DataNumber
                value={40}
                suffix="%"
              />
            }
          />
          <OneColumnData
            icon={IconWork}
            title="未経験入社の比率"
            width="33%"
            content={
              <DataNumber
                value={43}
                suffix="%"
              />
            }
          />
          <TwoColumnData
            icon={IconEscalatorWarning}
            title="パパママ率"
            width="50%"
            content1={
              <DataNumber
                value={29}
                suffix="%"
              />
            }
            content2={
              <DataList
                data={[
                  {
                    prefix: "パパ",
                    value: 6,
                    suffix: "人",
                    color: "#61B1C1",
                  },
                  {
                    prefix: "ママ",
                    value: 2,
                    suffix: "人",
                    color: "#DBAAC8",
                  },
                ]}
                flexDirection="column"
              />
            }
          />
          <OneColumnData
            icon={IconCoin}
            title="ネクストコイン"
            width="50%"
            content={
              <DataCoin />
            }
          />
        </DataContainer>
      </CategoryContainer>

      <CategoryContainer>
        <CategoryTitle>働く人たち</CategoryTitle>
        <DataContainer>
          <TwoColumnData
            icon={IconApartment}
            title="出社派or在宅派orハイブリッドワーク派？"
            width="50%"
            content1={<DataGraph graph={WorkstyleGraph} width="184px" height="184px" />}
            content2={
              <DataList
              data={[
                {
                  prefix: "出社派",
                  value: 32,
                  suffix: "%",
                  color: "#61B1C1",
                },
                {
                  prefix: "在宅派",
                  value: 20,
                  suffix: "%",
                  color: "#DBAAC8",
                },
                {
                  prefix: "ハイブリッドワーク派",
                  value: 48,
                  suffix: "%",
                  color: "#DDD88C",
                },
              ]}
              flexDirection="column"
            />}
          />
          <TwoColumnData
            icon={IconTrain}
            title="通勤時間"
            width="50%"
            content1={<DataGraph graph={TrainGraph} width="184px" height="184px" />}
            content2={
              <DataList
              data={[
                {
                  prefix: "〜30分",
                  value: 19,
                  suffix: "%",
                  color: "#61B1C1",
                },
                {
                  prefix: "30分〜1時間",
                  value: 46,
                  suffix: "%",
                  color: "#DBAAC8",
                },
                {
                  prefix: "1時間〜",
                  value: 35,
                  suffix: "%",
                  color: "#DDD88C",
                },
              ]}
              flexDirection="column"
            />}
          />
        </DataContainer>
      </CategoryContainer>
    </Wrapper>
  )
}
