import React from "react";
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"
import { DataTitle } from "./DataTitle";

import { FadeIn } from '../../../../../common/FadeIn'
import { FadeInOptions } from './../../../FadeInOptions'

const Wrapper = styled.div<{width: string}>`
  width: 100%;
  max-width: calc(${props => props.width} - 12px);
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: calc(${props => props.width} - 8px);
  }
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    max-width: 100%;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  padding: 24px;
  box-sizing: border-box;

  background: rgba(244, 244, 244, 1);

  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    padding: 16px;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  min-height: 154px;
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    min-height: auto;
  }
`

type Props = {
  width?: string
  icon: string
  title: string
  content1: any
  content2: any
};

export const TwoColumnData: React.VFC<Props> = (props) => {
  const { width = "50%", icon, title, content1, content2 } = props;
  const options = {...FadeInOptions, rootMargin: "0px"};

  return (
    <Wrapper width={width}>
      <FadeIn {...options}>
        <Inner>
          <DataTitle icon={icon} title={title} />
          <Content>
            {content1}
            {content2}
          </Content>
        </Inner>
      </FadeIn>
    </Wrapper>
  )
}
