import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../../../template/Layout"
import Head from "../../../head";
import { SectionTitle } from "../../common/SectionTitle"
import { Props as BreadcrumbsData } from "../../common/Breadcrumbs"
import { RESPONSIVE_STYLES, RECRUIT_PAGE_STYLES } from "../../../AppConst"
import { ogpUrl } from "../../../utlis/ImageUtil"
import { Member } from "./Member"
import { IntervewBlog } from "./Interview"
import { Gallery } from "./Gallery"
import { Data } from "./components/data";
import { Questionnaire } from "./components/questionnaire";

/**
 * Styles
 */
const Wrapper = styled.div`
  text-align: center;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding-top: 68px;
  }
`

const HeadLineContainer = styled.div`
  ${RECRUIT_PAGE_STYLES.MARGIN_TOP_STYLE}
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 160px;
  border-bottom: 2px solid #BDBDBD;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding-bottom: 64px;
  }
`

/**
 * Props
 */
type Props = {
  headerImg: any;
  breadcrumbsData: BreadcrumbsData["values"];
}


/**
 * Component
 */
export const CoWorkers: React.FC<Props> = (props) => {
  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title="働く人・環境 | 採用情報 | ネクストライブ株式会社"
        description="ネクストライブ株式会社の働く人・環境の紹介ページです。"
        img={ogpUrl(process.env.NODE_ENV, props.headerImg)}
      />
      <Wrapper>
        <SectionTitle title="ENVIRONMENT" subTitle="働く人・環境"/>
        <HeadLineContainer>
          <Img fluid={props.headerImg} alt="recruit-coworkers"/>
        </HeadLineContainer>

        <DataContainer>
          <Data />
          <Questionnaire />
        </DataContainer>

        <Member/>
        <IntervewBlog />
        <Gallery/>
      </Wrapper>
    </Layout>
  )
}
