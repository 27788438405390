import React from "react";
import styled from "styled-components";

import { Wrapper } from "../../components/common/Wrapper";
import { TitleWrapper } from "../common/TitleWrapper";
import { TitleAndCaption } from "../../../common/TitleAndCaption";

import { RESPONSIVE_STYLES } from "../../../../../AppConst";
import { QA } from "./common/QA";
import { Impression } from "./common/Impression";
import { Ranking } from "./common/Ranking";

import IconCode from "./../images/code.svg"
import IconDesign from "./../images/design.svg"
import IconTerminal from "./../images/terminal.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 16px;
  }
`

const QAList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 120px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 80px;
  }
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
    gap: 40px;
  }
`

const RankingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 16px;
  }
`

type Props = {};
export const Questionnaire: React.VFC<Props> = () => {
  return (
    <Wrapper>
      <TitleWrapper>
        <TitleAndCaption title='ネクストライブの人たちに聞きました！' subTitle='questionnaire' />
      </TitleWrapper>
      <Container>
        <QAList>
          <QA
            title={"退勤後の過ごし方は？"}
            innerWidth={"965px"}
            answers={[
              {
                answer: ["家事や子どものお世話"],
                color: "",
                left: true,
              },
              {
                answer: ["資格勉強"],
                color: "#84C17E",
              },
              {
                answer: ["海外ドラマをみながら夕食"],
                color: "",
                left: true,
              },
              {
                answer: ["副業&家事"],
                color: "",
              },
              {
                answer: ["光の射す方へ向かいます"],
                color: "#DDD88C",
                left: true,
              },
              {
                answer: ["ゲーム作成"],
                color: "",
              },
              {
                answer: ["VRの世界にログイン"],
                color: "#DBAAC8",
                left: true,
              },
              {
                answer: ["◯◯大学の勉強"],
                color: "#A1ADDD",
              },
              {
                answer: ["リビングのお気に入りのソファーで", "ゴロリ＆美味しいコーヒー"],
                color: "",
                left: true,
              },
              {
                answer: ["友達と遊ぶ！"],
                color: "#EDB568",
              },
            ]}
          />

          <QA
            title={"入社の決め手は？"}
            answers={[
              {
                answer: ["顧客満足度の高いWebサービスを", "作りたいと思ったから"],
                color: "",
                left: true,
              },
              {
                answer: ["この人たちと一緒に", "働きたいと思ったから"],
                color: "#DDD88C",
              },
              {
                answer: ["オフィスがおしゃれ"],
                color: "",
                left: true,
              },
              {
                answer: ["社長がいい人"],
                color: "#84C17E",
              },
              {
                answer: ["冷静さの中に情熱を秘めた", "社長の雰囲気に惹かれました"],
                color: "",
                left: true,
              },
              {
                answer: ["新しい技術に", "着目していたため"],
                color: "",
              },
              {
                answer: ["社長が大事にされている事と", "自分が大事にしたい事が同じだった"],
                color: "#61B1C1",
                left: true,
              },
              {
                answer: ["面白そうだった"],
                color: "",
              },
              {
                answer: ["ネクストライブで会社と共に", "成長したいと思いました"],
                color: "#DBAAC8",
              },
            ]}
          />

          <Impression
            title={"職場の雰囲気を一言で表現すると？"}
            innerWidth={"980px"}
            answers={[
              {
                answer: "メリハリがある",
                fontSize: 32,
              },
              {
                answer: "スピード感がある",
              },
              {
                answer: "風通しめっちゃ良い",
                color: "#DBAAC8",
                fontSize: 48,
              },
              {
                answer: "パワフル！ポジティブ！",
                color: "#EDB568",
              },
              {
                answer: "クリエイティブ",
                fontSize: 32,
              },
              {
                answer: "ダークな雰囲気（黒くて暗い雰囲気）",
              },
              {
                answer: "やわらかい",
              },
              {
                answer: "善",
                color: "#61B1C1",
                fontSize: 48,
              },
              {
                answer: "近すぎず遠すぎず",
              },
              {
                answer: "ゆる～い",
                color: "#84C17E",
                fontSize: 32,
              },
              {
                answer: "楽しい！",
              },
              {
                answer: "誰にでも話しやすい環境",
                color: "#A1ADDD",
                fontSize: 48,
              },
            ]}
          />

          <QA
            title={"メンバーの魅力は？"}
            answers={[
              {
                answer: ["誰かのために何かすることを","いとわない人が多いです"],
                color: "#EDB568",
              },
              {
                answer: ["スキがあれば", "ちょっとふざけたがる"],
                color: "",
                left: true,
              },
              {
                answer: ["何かしらに", "特化してる人がいる"],
                color: "",
              },
              {
                answer: ["とにかくみんな前向きで、", "心を引っ張ってもらえる"],
                color: "",
              },
              {
                answer: ["色々なバックグラウンドの人が", "集まってて面白い"],
                color: "",
              },
              {
                answer: ["些細な意見も", "聞き入れてもらえる"],
                color: "#84C17E",
                left: true,
              },
              {
                answer: ["ゆる～い" , "（部分がある）"],
                color: "",
              },
              {
                answer: ["みんなが自主性を持って", "主体的に会社を良くしようと", "日々研鑽を重ねているところが魅力的"],
                color: "#61B1C1",
                left: true,
              },
              {
                answer: ["仕事を楽しんでる人が多い"],
                color: "#DBAAC8",
              },
            ]}
          />

          <QA
            title={"未来の自分はどうなっていたい？"}
            answers={[
              {
                answer: ["模索中です"],
                color: "",
              },
              {
                answer: ["社内ベンチャーで", "今以上の大きな責任を背負って", "“あるコト”をしたい"],
                color: "#EDB568",
                left: true,
              },
              {
                answer: ["江田島平八郎"],
                color: "",
              },
              {
                answer: ["自分が自分に納得できる", "存在になりたい"],
                color: "#DDD88C",
                left: true,
              },
              {
                answer: ["どこでも活躍できる実力を持ちながらも", "ネクストで最大限その力を発揮できる人"],
                color: "",
              },
              {
                answer: ["どのような形であれ", "生涯エンジニア"],
                color: "",
                left: true,
              },
              {
                answer: ["冒険家"],
                color: "#84C17E",
              },
              {
                answer: ["ネクストの裏ボス"],
                color: "",
                left: true,
              },
              {
                answer: ["周りを良い方向へ", "巻き込める人"],
                color: "#DBAAC8",
              },
              {
                answer: ["デザイナーの枠を超えた仕事を", "できる人になりたい"],
                color: "",
              },
              {
                answer: ["エンジニア×他職種の活躍ができる", "「ハイブリット職」"],
                color: "#61B1C1",
                left: true,
              },
            ]}
          />

          <QA
            title={"家族や友人からのネクストの印象は？"}
            innerWidth={"980px"}
            answers={[
              {
                answer: ["何でそんなに", "いい雰囲気なんですか？"],
                color: "#61B1C1",
                left: true,
              },
              {
                answer: ["都会って感じやねぇ〜"],
                color: "",
              },
              {
                answer: ["子供達はネクストの人が", "大好きです！"],
                color: "#DDD88C",
                left: true,
              },
              {
                answer: ["カッコよさそうな会社"],
                color: "",
                left: true,
              },
              {
                answer: ["まさに理想の社長！"],
                color: "#84C17E",
              },
              {
                answer: ["おもしろそうなとこで", "働いてるな"],
                color: "",
                left: true,
              },
              {
                answer: ["いきってますね～", "（いい意味で）"],
                color: "#A1ADDD",
              },
              {
                answer: ["マリル推しです"],
                color: "",
                left: true,
              },
              {
                answer: ["他に無いってくらい、", "合ってると思う"],
                color: "",
              },
            ]}
          />
          <RankingContainer>
            <Ranking icon={IconCode} title="好きな開発言語は？" name="C#" names={["Ruby", "Java", "Python", "Blueprint", "VBA", "C++", "SQL"]} />
            <Ranking icon={IconTerminal} title="好きなエディタは？" name="VisualStudioCode" names={["Vim", "秀丸エディタ", "サクラエディタ", "NotePad"]} />
            <Ranking icon={IconDesign} title="好きなデザインツールは？" name="Figma、Adobe XD" names={["Adobe Illustrator", "GIMP", "ペイントソフト", "Affinity Photo", "Canva", "Microsoft Designer", "CLIP STUDIO PAINT"]} />
          </RankingContainer>
        </QAList>
      </Container>
    </Wrapper>
  )
}
