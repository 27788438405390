import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby';

import { RESPONSIVE_STYLES } from '../../../AppConst'
import { TitleAndCaption } from '../common/TitleAndCaption'
import { RoundLinkButton } from '../../common/RoundLinkButton';
import { ReadMore } from '../../common/ReadMore'

import { ButtonWrapper } from '../common/ButtonWrapper';
import { MemberItem } from "../../member/common/MemberItem"
import { PickUpMemberContainer } from '../../member/common/PickUpMemberContainer';
import { SpWrapper } from '../../common/SpWrapper';

const Wrapper = styled.div`
  width: 100%;
  padding: 160px 20px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  color: #222222;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 80px 0;
  }
`

const ReadMoreWrapper = styled.div`
  text-align: center;
`

const MemberContainerWrapper = styled.div`
  max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px;
  margin: 0 auto 96px;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin: 0 auto 48px;
  }
`

export const Member:React.VFC = () => {
  const { site: { siteMetadata: { member } } } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          member {
            name
            position
            pathname
            alt
            member_index_filename
            displayed_member
            color
          }
        }
      }
    }
  `);
  return (
    <SpWrapper>
      <Wrapper>
        <TitleAndCaption title='一緒に働くメンバー' subTitle='MEMBER'/>
        <MemberContainerWrapper>
          <PickUpMemberContainer members={member}/>
        </MemberContainerWrapper>
        <ReadMoreWrapper>
          <ReadMore url='/member' text='メンバーをもっと見る'/>
        </ReadMoreWrapper>
      </Wrapper>
    </SpWrapper>
  )
}


