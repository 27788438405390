import React from "react"
import styled, {css} from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../../../AppConst"
import { Title } from "./Title";

import { FadeIn } from '../../../../../common/FadeIn'
import { FadeInOptions } from './../../../FadeInOptions'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
  }

  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    gap: 16px;
  }
`

const Inner = styled.div<{width: string}>`
  width: 100%;
  max-width: ${props => props.width};
  margin: auto;
`

const Answers = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px 24px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 8px;
  }

  & > div {
    display: flex;
    width: fit-content;
  }
`

const Answer = styled.span<{fontSize: number, color: string}>`
  display: flex;
  box-sizing: border-box;

  text-align: center;
  font-weight: 600;

  color: ${props => props.color};
  font-size: ${props => props.fontSize}px;
  line-height: ${props => props.fontSize * 1.5}px;
  letter-spacing: ${props => props.fontSize / 22 * 1.2}px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: ${props => props.fontSize * 0.66}px;
    line-height: ${props => props.fontSize * 1.5  * 0.66}px;
    letter-spacing: ${props => props.fontSize / 22 * 1.2  * 0.66}px;
  }

  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    font-size: ${props => props.fontSize * 0.5}px;
    line-height: ${props => props.fontSize * 1.5 * 0.5}px;
    letter-spacing: ${props => props.fontSize / 22 * 1.2 * 0.5}px;
  }
`

type Props = {
  title: string
  innerWidth?: string
  answers: Array<{
    answer: string
    color?: string
    fontSize?: number
  }>
};
export const Impression: React.VFC<Props> = (props) => {
  const { title, innerWidth = "1080px", answers } = props;

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Inner width={innerWidth}>
        <Answers>
          {
            answers.map((a, i) => {
              const {answer, color = "#222", fontSize = 24} = a;
              const options = {...FadeInOptions, delay: (i * 150)};
              return (
                <FadeIn {...options}>
                  <Answer fontSize={fontSize} color={color}>{answer}</Answer>
                </FadeIn>
              )
            })
          }
        </Answers>
      </Inner>
    </Wrapper>
  )
}
